import { render, staticRenderFns } from "./aging_opt.vue?vue&type=template&id=b1067a56&scoped=true&"
import script from "./aging_opt.vue?vue&type=script&lang=js&"
export * from "./aging_opt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1067a56",
  null
  
)

export default component.exports