<template>
    <div>
        <h1>AR Aging</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results @clicked=" (el) => getNotes(el)"/>
        </form>

        <Modal v-if="showNotes" heading="Notes" @close=" closeModal()">
            <template v-slot:body>
                <label><b>Invoice Notes</b></label>
                <div class="form-group" v-if="notes.invoice">
                    <span v-for='(note) in notes.invoice' v-bind:key='note.cdi_note_id'>
                        <p><b>{{ note.note_user }}</b> @ {{ note.cdi_note_date }}<br />
                        {{ note.note_txt }}</p> </span>
                </div>
                <br>
                <label><b>Notebook Notes</b></label>
                <div class="form-group" v-if="notes.build">
                    <span v-for='(note) in notes.build' v-bind:key='note.cdi_note_id'>
                        <p><b>{{ note.note_user }}</b> @ {{ note.cdi_note_date }}<br />
                        {{ note.note_txt }}</p> </span>
                </div>
                <div class="row">
                    <div class='col-xs-12'>
                        <textarea placeholder='Add Notes...' v-model="newNote" class='form-control'></textarea>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class='col-xs-12 spacing_top'>
                    <button id='add_invoice_note'  @click="addNote" class='btn btn-primary'>Add Invoice Note</button>
                </div>
            </template>
        </Modal>
    </div>

</template>

<script>

import {store} from '@/store/Report.store.js';
import Options from './aging_opt.vue';
import Results from './utils/Results';
import appStore from "@/store/App.store";
import Helpers from "@/store/helpers";
import Fields from './utils/Fields.vue'
import Modal from "@/components/utils/Modal";



export default {
    name: "aging",
    components: {
        Options,
        Results,
        Fields,
        Modal
    },

    data() {
        return {
            showNotes: false,
            newNote: null,
            notes: {
                invoice: {},
            },
            cdi_id: '',
            state: store.state,
            optionFields: {
                actualShip: {val: new Date().toLocaleDateString(), qsField: '[actual_ship]'},
                companyID: {val: 0, qsField: '[numbers][c.company_id]'},
                cdiLocationID: {val: 0, qsField: '[numbers][cdi_location_id]'},
                showTrackers: {val: 0, qsField: '[show_trackers]'},
            },
            groupByFields: {},
            dataFields: {
                current: { label: 'Current', checked: true },
                15: { label: '1-14', checked: true },
                31: { label: '15-30', checked: true },
                61: { label: '31-60', checked: true },
                91: { label: '61-90', checked: true },
                121: { label: '91+', checked: true },
                total: { label: 'Total', checked: true },
                credit: { label: 'Credit', checked: false },
            }
        }
    },
    props: ['action'],
    methods: {
        runReport: function () {
            store.runReport();
        },

        getNotes: function (el) {
            if (el.getAttribute('cdi_id')) {
                this.cdi_id = el.getAttribute('cdi_id');
                store.customAPI(`tracker/to_json_notebook/${this.cdi_id}`).then(data => this.notes = data.notes);
                this.showNotes = true;
            }
        },
        closeModal: function () {
            this.notes = {};
            this.showNotes = false;
        },
        addNote: function () {
            let params = {
                'params[cdi_note]': this.newNote
            };

            this.newNote = null;
            store.customAPI(`tracker/add_invoice_note/${this.cdi_id}`, params).then(data => this.notes = data.notes);
        },
    },
    created() {
        this.$appStore.setTitle(['AR Aging']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        store.state.extraTabs = [
            {label: 'Lock Orders', action: 'lockOrders'},
            {label: 'Send Past Due', action: 'send_past_due'}
        ];
    },
    watch: {
        action: function () {
            if (this.action === 'lockOrders') {
                store.customReport('aging', 'lock_trackers', {'params[actual_ship]': this.optionFields.actualShip.val}, false).then((data) => {
                    appStore.successBox(data.msg)
                });
            } else if (this.action === 'send_past_due') {
                store.customReport('aging', 'send_past_due', Helpers.getQSPairs(this.optionFields), false).then((data) => {
                    appStore.successBox(data.msg)
                });
            }
        }
    }
}
</script>

<style scoped>

</style>