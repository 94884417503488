<template>
    <div>
        <div class='panel panel-default'>
            <div class='panel-heading'></div>
            <div class='row panel-body'>
                <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                    <label>Invoice/Ship Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.actualShip.val" />
                </div>
                <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                    <label>Company</label>
                    <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyID.val" />
                </div>
                <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                    <label>CDI Location</label>
                    <select class="cdi_select form-control" v-model="state.optionFields.cdiLocationID.val">
                        <option value="0">All</option>
                        <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                    </select>
                </div>

                <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                    <label><input type='checkbox' v-model="state.optionFields.showTrackers.val" :true-value="1" :false-value="0"  > Show Orders</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import Typeahead from '@/components/utils/typeaheads/Typeahead';
    import DatePicker from "@/components/utils/DatePicker";


    export default {
        name: "Options",
        components: {
            Typeahead,
            DatePicker
        },

        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                },
                showNotes: false
            }
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
            }
        },
        watch:{
            toggleHide: function (toggleHide) {
                if(toggleHide === true){
                    this.state.optionFields.option121.val = 0;
                    this.state.optionFields.option91.val = 0;
                    this.state.optionFields.option61.val = 0;
                    this.state.optionFields.option31.val = 0;
                    this.state.optionFields.option15.val = 0;
                    store.runReport()
                }
            },
        }
    }
</script>

<style scoped>

</style>